export default function TimeStamp(seconds) {
  var pad = function (num, size) {
    return ("000" + num).slice(size * -1);
  };

  function conv(t, diff) {
    var first, second;
    var time = parseFloat(t).toFixed(3);
    if (diff < 3600){
        first = Math.floor(time / 60) % 60;
        second = Math.floor(time - first * 60)
    }
    else{
        first = Math.floor(time / 60 / 60)
        second = Math.floor(time / 60) % 60;
    }
      return pad(first, 2) + ":" + pad(second, 2);
  }

  var arr = [0];
  var diff = 0;
  var pre = 0;
  for (var t = 0; t < 19; t++) {
    if (diff === 0) diff = seconds / 19;
    arr.push(pre + diff);
    pre = pre + diff;
  }
  console.log(arr)
  return arr.map((e) => conv(e, diff));
}
