import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import waveform from "./waveform.png";
import "./AudioTextSync.css";
import { Row, Col } from "react-bootstrap";
import TimeStamp from "../utils/TimeStamp";
import "./Classes.css";
import {
  getAudioTextData,
  updateAudioTextSync,
} from "../services/firebaseServices";
import { engNumToHeb } from "../utils/utils";
import { getAudioFromChapter } from "../services/ApiService";
export const AudioTextSync = ({ header, media }) => {
  const ref = useRef(null);
  const dragRef = useRef(null);
  const [duration, setDuration] = useState(1);
  const chapterId = media.chapterId;
  const [oldTextSyncMap, setOldTextSyncMap] = useState(media.textSyncMap ?? {});
  const [textSyncMap, settextSyncMap] = useState(oldTextSyncMap);
  const [oldNonIndexedTextSyncMap, setOldNonIndexedTextSyncMap] = useState(
    media.nonIndexedTextSyncMap ?? {}
  );
  const [nonIndexedTextSyncMap, setNonIndexedTextSyncMap] = useState(
    oldNonIndexedTextSyncMap
  );
  const [time, setTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [width, setWidth] = useState(0);
  const [timeStamps, setTimeStamps] = useState([]);
  const [currentPararaph, setCurrentPararaph] = useState();
  const [currentPararaphNi, setCurrentPararaphNi] = useState();
  const [audioTextData, setAudioTextData] = useState([]);
  const speeds = [1, 1.25, 1.5, 1.75, 2, 0.5];
  const [currentSpeed, setSpeed] = useState(1);
  useEffect(() => {
    if (textSyncMap != oldTextSyncMap) {
      onSave();
    }
  }, [textSyncMap]);

  useEffect(() => {
    if (nonIndexedTextSyncMap != oldNonIndexedTextSyncMap) {
      onSave();
    }
  }, [nonIndexedTextSyncMap]);

  async function getData() {
    const res = await getAudioFromChapter(chapterId);
    let _data = res.data.verses;
    let _count = 1;
    _data.forEach((item, index) => {
      if (!item.native.number) {
        _data[index].native.number = _count.toString();
        _count++;
      }
    });
    setAudioTextData(_data);

    // var data = await getAudioTextData(media.path);
    // console.log(data[0]);
    // setAudioTextData(data[0]);
    if (dragRef.current) setWidth(dragRef.current.offsetWidth);
  }

  useEffect(() => {
    getData();
  }, []);

  const onSave = () => {
    console.log(media.id);
    setOldTextSyncMap(textSyncMap);
    setOldNonIndexedTextSyncMap(nonIndexedTextSyncMap);
    updateAudioTextSync(textSyncMap, nonIndexedTextSyncMap, media.id);
  };

  useEffect(() => {
    let paragraph;
    let isIndexed;
    for (
      let index = 0;
      index < Object.entries(nonIndexedTextSyncMap).length;
      index++
    ) {
      if (
        (Object.values(nonIndexedTextSyncMap)[index] | 0) ===
        (ref.current.currentTime | 0)
      ) {
        paragraph = parseInt(Object.keys(nonIndexedTextSyncMap)[index]);
        break;
      } else if (
        (Object.values(nonIndexedTextSyncMap)[index] | 0) <=
        (ref.current.currentTime | 0)
      ) {
        if (!Object.values(nonIndexedTextSyncMap)[index + 1]) {
          paragraph = parseInt(
            Object.keys(nonIndexedTextSyncMap)[
              Object.keys(nonIndexedTextSyncMap).length - 1
            ]
          );
        } else {
          if (
            (Object.values(nonIndexedTextSyncMap)[index + 1] | 0) >
            (ref.current.currentTime | 0)
          ) {
            paragraph = parseInt(Object.keys(nonIndexedTextSyncMap)[index]);
          }
        }
      }
    }
    for (let index = 0; index < Object.entries(textSyncMap).length; index++) {
      if (
        (Object.values(textSyncMap)[index] | 0) ===
        (ref.current.currentTime | 0)
      ) {
        paragraph = parseInt(Object.keys(textSyncMap)[index]);
        isIndexed = true;
        break;
      } else if (
        (Object.values(textSyncMap)[index] | 0) <=
        (ref.current.currentTime | 0)
      ) {
        if (!Object.values(textSyncMap)[index + 1]) {
          paragraph = parseInt(
            Object.keys(textSyncMap)[Object.keys(textSyncMap).length - 1]
          );
          isIndexed = true;
        } else {
          if (
            (Object.values(textSyncMap)[index + 1] | 0) >
            (ref.current.currentTime | 0)
          ) {
            paragraph = parseInt(Object.keys(textSyncMap)[index]);
            isIndexed = true;
          }
        }
      }
    }
    if (paragraph) {
      if (isIndexed) setCurrentPararaph(paragraph);
      else setCurrentPararaphNi(paragraph);
    }
  }, [time]);
  useEffect(() => {
    if (currentPararaph) setCurrentPararaphNi(undefined);
    if (document.getElementById(`para-${currentPararaph}`) && currentPararaph) {
      document.getElementById(`para-${currentPararaph}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentPararaph]);
  useEffect(() => {
    if (currentPararaphNi) setCurrentPararaph(undefined);
    if (
      document.getElementById(`para-ni-${currentPararaphNi}`) &&
      currentPararaphNi
    ) {
      document.getElementById(`para-ni-${currentPararaphNi}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentPararaphNi]);
  useEffect(() => {
    const audio = ref.current;
    audio.src = media.url;
    const onDuration = () => {
      setDuration(audio.duration);
      setTimeStamps(TimeStamp(audio.duration));
    };
    const onTime = () => {
      setTime(audio.currentTime);
    };

    const onSeeking = () => {
      console.log("seeking...");
      // audio.volume = 0;
    };
    const onSeeked = () => {
      console.log("seeked");
      // audio.volume = 1;
    };

    const onKeydown = (e) => {
      if (e.keyCode === 32 && audio.paused === true) {
        e.preventDefault();
        audio.play();
        setPlaying((val) => true);
      } else if (e.keyCode === 32 && audio.paused === false) {
        e.preventDefault();
        audio.pause();
        setPlaying((val) => false);
      } else if (e.keyCode === 37) {
        audio.currentTime -= 5;
      } else if (e.keyCode === 39) {
        audio.currentTime += 5;
      }
    };

    audio.addEventListener("durationchange", onDuration);
    window.addEventListener("keydown", onKeydown);
    audio.addEventListener("timeupdate", onTime);
    audio.addEventListener("seeking", onSeeking);
    audio.addEventListener("seeked", onSeeked);

    return () => {
      audio.muted = true;
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(dragRef.current.offsetWidth);
    };

    if (dragRef.current) {
      setWidth(dragRef.current.offsetWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dragRef]);

  return (
    <div>
      <audio ref={ref}>
        <source src={media.url} />
      </audio>
      <>
        <div
          style={{
            margin: "50px",
          }}
        >
          <Row className="align-items-center">
            <Col
              md={
                oldTextSyncMap != textSyncMap ||
                nonIndexedTextSyncMap != oldNonIndexedTextSyncMap
                  ? 10
                  : 12
              }
            >
              <span>{header}</span>
            </Col>
            {oldTextSyncMap != textSyncMap ||
            nonIndexedTextSyncMap != oldNonIndexedTextSyncMap ? (
              <Col className="text-right">
                <p className="save" onClick={onSave}>
                  Save
                </p>
              </Col>
            ) : (
              <div />
            )}
          </Row>
          <div className="divider"></div>
          <div style={{ marginTop: "6.4vh" }}>
            {audioTextData.map((e, i) => {
              return (
                <p
                  id={
                    "para-" +
                    (!e["hide-verse-number"] ? "" : "ni-") +
                    e.native.number
                  }
                  dangerouslySetInnerHTML={{
                    __html: !e["hide-verse-number"]
                      ? `<span class="first">${e.hebrew.number} </span>${e.hebrew.text}</span>`
                      : `<span>${e.hebrew.text}</span>`,
                  }}
                  className={`paragraph${
                    i + 1 === audioTextData.length ? " last" : ""
                  }${
                    (
                      !e["hide-verse-number"]
                        ? currentPararaph === parseInt(e.native.number)
                        : currentPararaphNi === parseInt(e.native.number)
                    )
                      ? " current"
                      : ""
                  }`}
                  style={{ direction: "rtl", textAlign: "right" }}
                  onClick={() => {
                    console.log(
                      "Paragraph: ",
                      parseInt(e.native.number),
                      "\n",
                      "Audio Position:",
                      time
                    );
                    if (!e["hide-verse-number"]) {
                      settextSyncMap((val) => {
                        val[parseInt(e.native.number)] = time;
                        return { ...val };
                      });
                      setCurrentPararaph(parseInt(e.native.number));
                    } else {
                      setNonIndexedTextSyncMap((val) => {
                        val[parseInt(e.native.number)] = time;
                        return { ...val };
                      });
                      setCurrentPararaphNi(parseInt(e.native.number));
                    }
                  }}
                ></p>
              );
            })}
          </div>
        </div>
        <div
          className="background"
          onClick={(e) => {
            if (e.target.className !== "p-no" && e.target.id !== "speed") {
              setTime(
                () =>
                  (e.nativeEvent.offsetX / dragRef.current.offsetWidth) *
                  duration
              );
              ref.current.currentTime =
                (e.nativeEvent.offsetX / dragRef.current.offsetWidth) *
                duration;
            }
          }}
          ref={dragRef}
        >
          {Object.keys(textSyncMap).map((key) => (
            <div
              className="p-no"
              style={{
                marginLeft: `${(textSyncMap[key] / duration) * 100}%`,
              }}
              onClick={() => {
                setTime(() => textSyncMap[key]);
                ref.current.currentTime = textSyncMap[key];
              }}
            >
              {key}
            </div>
          ))}
          {Object.keys(nonIndexedTextSyncMap).map((key) => (
            <div
              className="p-no"
              style={{
                marginLeft: `${(nonIndexedTextSyncMap[key] / duration) * 100}%`,
                backgroundColor: "#D3D3D3",
                color: "#126462",
              }}
              onClick={() => {
                setTime(() => nonIndexedTextSyncMap[key]);
                ref.current.currentTime = nonIndexedTextSyncMap[key];
              }}
            >
              {key}
            </div>
          ))}
          {dragRef.current != null ? (
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              {[...Array(19)].map((e, i) => (
                <div
                  style={{
                    display: "block",
                    float: "left",
                    pointerEvents: "none",
                  }}
                >
                  <span className="time-text">
                    ‎{i % 2 == 0 ? timeStamps[i] : ""}
                  </span>
                  <div
                    className="line"
                    style={{
                      marginRight: `${(width - 96) / 95}px`,
                    }}
                  ></div>
                  {[...Array(4)].map((e, i) => (
                    <div
                      className="line-mm"
                      style={{
                        marginRight: `${(width - 96) / 95}px`,
                      }}
                    ></div>
                  ))}
                </div>
              ))}
              <div
                style={{
                  float: "left",
                }}
              >
                <span className="time-text">‎</span>
                <div className="line"></div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div
            id="waveform"
            style={{
              backgroundRepeat: "repeat-x",
              backgroundPosition: "start",
              backgroundImage: `url(${waveform})`,
              backgroundSize: "900px",
              width: "100%",
              height: "8.8vh",
              marginTop: "4.3vh",
              zIndex: 1,
              display: "block",
              position: "absolute",
            }}
          ></div>
          <div
            id="speed"
            onClick={() => {
              setSpeed((e) => {
                var i = speeds.findIndex((val) => val === e);
                if (i === speeds.length - 1) {
                  i = 0;
                } else {
                  i++;
                }
                ref.current.playbackRate = speeds[i];
                return speeds[i];
              });
            }}
          >
            {currentSpeed}x
          </div>
          <div
            id="progress"
            style={{ marginLeft: `${(time / duration) * 83}%` }}
          ></div>
        </div>
      </>
    </div>
  );
};

export default AudioTextSync;
