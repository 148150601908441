import React from "react";
import { FaFolder } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import "./Folder.css";
function Folder(props) {
  return (
    <div
      className={props.forGrid ? "border" : "vertical"}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
      style={{
        backgroundColor: props.selected ? "rgba(213, 228, 227, 0.44)" : "white",
      }}
    >
      {" "}
      {!props.forGrid ? (
        <span className="folder">
          <FaFolder size={26} color="#126664" />
          &nbsp;&nbsp;&nbsp; {props.title}
        </span>
      ) : (
        <Row>
          <Col xs={2}>
            <FaFolder size={26} color="#126664" />
          </Col>
          <Col>
            <div className="folder">{props.title}</div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Folder;
