import React, { useState, useEffect } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import DragDrop from "../drag-and-drop";
import ImageEditor from "../ImageEditor";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { Virtuoso } from "react-virtuoso";
import {
  uploadFileToStorage,
  uploadAudio,
  updateAudio,
} from "../../services/firebaseServices";
import { MdPersonOutline } from "react-icons/md";
import { v4 } from "uuid";
import structure from "../../../src/data/structure.json";
import chapternames from "../../../src/data/chapternames.json";
const AssignTeacher = ({
  show,
  onHide,
  teachers,
  media,
  file,
  path,
  onAdd,
  initialTeacher,
  onEdit,
}) => {
  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const onExit = () => {
    onHide();
  };

  useEffect(() => {
    if (initialTeacher !== null) {
      setSelectedTeacher((val) => initialTeacher);
    }
  }, []);

  const [selectedTeacher, setSelectedTeacher] = useState({
    id: "s",
  });

  function onSave() {
    if (initialTeacher == null) {
      let name = file.name;
      console.log(file);
      console.log("onSave");
      setUploading(true);
      uploadFileToStorage(
        `Mishnah Torah/${path.split("classes/")[1]}/${v4()}`,
        file,
        (val) => setCompleted(val),
        async (val) => {
          var duration = 0;
          var audio = document.createElement("audio");
          var objectUrl = URL.createObjectURL(file);
          audio.src = objectUrl;
          audio.addEventListener("canplaythrough", async function (e) {
            if (duration === 0) {
              var seconds = e.currentTarget.duration;
              duration = seconds * 1000;
              console.log(
                "The duration of the song is of: " + duration + " seconds"
              );
              if (duration === 0) {
                alert("Duration is Zero");
              }
              URL.revokeObjectURL(objectUrl);
              const [book, topic, chapter] = path
                .split("classes/")[1]
                .split("/");
              const chapterId = structure
                .find((e) => e.name == book)
                .topics.find((e) => e.name == topic).chapters[
                parseInt(chapter) - 1
              ];
              var id = await uploadAudio("Audio", {
                url: val,
                chapterId: chapterId,
                chapterName: chapternames[chapterId.toString()],
                path: path.split("classes/")[1],
                teacher: selectedTeacher.id,
                duration: duration,
              });
              setUploading(false);
              console.log(id);
              onAdd({
                id: id,
                url: val,
                path: path.split("classes/")[1],
                teacher: selectedTeacher.id,
                duration: duration,
              });
              onHide();
            }
          });
        }
      );
    } else {
      if (initialTeacher.id === selectedTeacher.id) {
        onHide();
      } else {
        updateAudio(
          {
            teacher: selectedTeacher.id,
          },
          media.id
        );
        onEdit({ ...media, ...{ teacher: selectedTeacher.id } });
        onHide();
      }
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        contentClassName="custom-modal-style"
        onEscapeKeyDown={onExit}
        onExit={onExit}
        centered
      >
        <Modal.Body style={{ padding: "4vh" }}>
          <div className="d-flex justify-content-center">
            <p
              style={{ fontSize: "31px", fontWeight: "bold", color: "#454545" }}
            >
              Who is giving the class?
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <Virtuoso
              style={{
                height: "460px",
                width: "100%",
                borderRadius: "6px",
                border: "1px solid rgba(18,102,102,0.44)",
                marginBottom: "40px",
              }}
              totalCount={teachers.length}
              itemContent={(index) => {
                if (selectedTeacher !== undefined)
                  return (
                    <TeacherItemTile
                      teacher={teachers[index]}
                      key={teachers[index].id}
                      onSelected={(e) => {
                        setSelectedTeacher(() => e);
                      }}
                      clicked={selectedTeacher.id === teachers[index].id}
                    />
                  );
                else
                  return (
                    <TeacherItemTile
                      teacher={teachers[index]}
                      key={teachers[index].id}
                      onSelected={(e) => {
                        setSelectedTeacher(() => e);
                      }}
                      clicked={false}
                    />
                  );
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: "#126664",
                border: "1px solid #126664",
                height: "5vh",
                width: "8vw",
                margin: "0px 0.8vw",
              }}
              onClick={onExit}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#126664",
                color: "#fff",
                border: "1px solid rgba(0,0,0,0)",
                height: "5vh",
                width: "8vw",
                margin: "0px 0.8vw",
              }}
              onClick={onSave}
            >
              Upload
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {uploading && (
        <Modal show={uploading} centered size="sm">
          <Modal.Body>
            <ProgressBar animated now={completed} max={100} />
            <p
              style={{ fontSize: "20px", textAlign: "center" }}
            >{`Uploading ${completed}% out of ${100}%`}</p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const TeacherItemTile = ({ teacher, onSelected, clicked }) => {
  const onClick = () => {
    onSelected(teacher);
  };

  return (
    <span
      className="text-center"
      className={`teacher-item-tile ${clicked ? "clicked" : ""}`}
      onClick={onClick}
    >
      <MdPersonOutline size={30} color={`${clicked ? "#fff" : "#126664"}`} />
      &nbsp;&nbsp;&nbsp;
      {teacher.name}
    </span>
  );
};

export default AssignTeacher;
