import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Container, Row, Col, Nav, Navbar, Toast } from "react-bootstrap";
import Logo from "../components/Logo"
import { signIn } from "../services/firebaseServices";
import { useHistory } from "react-router-dom";


function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    let history = useHistory();


    const emailChanged = (e) => {
        e.preventDefault();
        setEmail(() => e.target.value);
    }
    const passwordChanged = (e) => {
        e.preventDefault();
        setPassword(() => e.target.value);
    }
    const signingIn = async (e) => {
        e.preventDefault();
        console.log(e);
        await signIn(email, password, (err) => {
            setShow(true);
            setError(() => err.message);
        },(val) => {
            console.log(val);
            history.replace("")
        });

    }
    return (
        <>
            <Container fluid style={{ backgroundColor: "#ECF3F3", height: "100%", width: " 100%" }}>
                <form  method="post" onSubmit={signingIn}>
                    <Row>
                        <Col xs={3} id="sidebar-wrapper">
                            <Container style={{ backgroundColor: "#fff", height: "100%", width: " 100%" }}>
                                <Nav
                                    className="col-md-3 bg-white sidebar flex-column d-none d-md-block "
                                    activeKey="/home"
                                >
                                    <div style={{ padding: "10vh 0vh 5vh 0vh" }}><Logo /></div>
                                    <div className="text-center"><input className="login-field" type="email" placeholder="Email" onChange={emailChanged} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required/></div>
                                    <div className="text-center"><input className="login-field pwd" type="password" placeholder="Password" minLength={6} onChange={passwordChanged} onSubmit={(e) => console.log("submit")} required/></div>
                                    <div className="d-flex justify-content-center">
                                        <input
                                            type="submit"
                                            style={{
                                                border: " 1px solid rgba(0,0,0,0)",
                                                backgroundColor: "#126664",
                                                color: "#fff",
                                                borderRadius: "1.4vh",
                                                fontSize: "1.1vw",
                                                fontWeight: "bold",
                                                margin: "5vh 0vh",
                                                height: "6vh",
                                                width: "9vw",
                                            }}
                                            value="Sign In"
                                            // onClick={signingIn}
                                        /></div>
                                    <Navbar.Text id="bottom" style={{ color: "#126664" }}>
                                        Powered By <span style={{ fontWeight: "bold" }}>Osfy</span>
                                    </Navbar.Text>
                                </Nav>
                            </Container>
                        </Col>

                    </Row>
                </form>
            </Container>
            <Toast show={show} style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
            }} delay={1000} onClose={() => setShow(false)} autohide>
                <Toast.Header>
                    <strong className="mr-auto">Rambam</strong>
                </Toast.Header>
                <Toast.Body>{error}</Toast.Body>
            </Toast>
        </>
    );
}

export default Login;
