import React from "react";
import logo from './logo.png'
function Logo() {
  return (
    <div className="align-items-center" style={{flex:'1'}}>
      <img
        src={logo}
        style={{
          display: "block",
          margin: "auto",
          maxHeight: "10vh",
        }}
      />
    </div>
  );
}

export default Logo;
