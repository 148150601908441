
import { MdPersonOutline, MdDelete } from 'react-icons/md';
import { Row, Col } from "react-bootstrap";
import "./Folder.css";
import { msToTime } from '../utils/utils';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import DeleteConfirm from './Dialogs/delete-confirm';
import React from 'react';
import { deleteAudio, deleteFileToStorage } from '../services/firebaseServices';
import { FaRegEdit } from 'react-icons/fa';
import AssignTeacher from './Dialogs/assign-teacher';

function Media(props) {
  const [deleteShow, setDeleteShow] = React.useState(null);
  const [editShow, setEditShow] = React.useState(null);
  console.log(props.media.teacher)
  const onDeleteClick = () => {
    console.log('delete');
    setDeleteShow(true);
    console.log(deleteShow);
  }

  const onDeleted = async () => {
    props.onDelete(props.media);
    console.log(props.media.id)
    const path = props.media.path
    const url = props.media.url;
    const _path = path.replaceAll("/", "%2F").replaceAll(" ", "%20")
    const fullPath = ("Mishnah Torah/" + path + url.split(`${_path}`)[1].split("?")[0].replaceAll("%20", " ").replaceAll("%2F", "/"))
    await deleteAudio(props.media.id)
    await deleteFileToStorage(fullPath);
  }
  return (
    <>
      <ContextMenuTrigger id={props.media.id}>
        <div
          className={props.forGrid ? "border" : "vertical"}
          onClick={props.onClick}
          onDoubleClick={props.onDoubleClick}
          style={{
            backgroundColor: props.selected ? "rgba(213, 228, 227, 0.44)" : "white",
          }}
        >
          {" "}
          <Row style={{
            padding: "0px 20px"
          }}>
            <div style={{ width: "2vw" }} className="text-center">
              <MdPersonOutline size={26} color="#126664" />
            </div>
            <Col>
              <div stlye={{ fontSize: 27, color: "#454545" }}>{props.media.teacher.name}</div>
            </Col>
            <Col xs={1}>
              <div stlye={{ fontSize: 27, color: "rgba(69,69,69,0.49)" }}>{msToTime(props.media.duration)}</div>
            </Col>
          </Row>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={props.media.id}>
        <MenuItem onClick={() => { setEditShow(true); }}><FaRegEdit color="#126664" /><span>Edit</span></MenuItem>
        <MenuItem onClick={onDeleteClick}><MdDelete color="#126664" /><span>Delete</span></MenuItem>
      </ContextMenu>
      <DeleteConfirm show={deleteShow} onHide={() => setDeleteShow(false)} onClick={onDeleted} />
      {editShow && <AssignTeacher show={editShow} media={props.media} onHide={() => setEditShow(false)} teachers={props.teachers} onEdit={props.onEdit} initialTeacher={props.media.teacher} />}
    </>
  );
}

export default Media
