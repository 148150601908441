import { React, useState, useEffect } from "react";
import TeacherTile from "../components/TeacherTile";
import { FaPlus } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import "./Classes.css";
import "./Teachers.css";
import { getTeachers } from "../services/firebaseServices";
import AddTeacher from "../components/Dialogs/add-teacher";
function Teacher() {
  const [show, setShow] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const teacher = await getTeachers();
    setTeachers(() => teacher);
    setLoading(val => false)
    console.log(teacher);
  }

  const onUpdate = (teacher) => {
    setTeachers(teachers => teachers.map(ele => {
      if(ele.id === teacher.id){
        return teacher
      }
      return ele
    }))
  }
  const onDelete = (teacher) => {
    setTeachers(teachers => teachers.filter(ele => 
      ele.id !== teacher.id))
  }

  const onAdd = (teacher) => {
    setTeachers(teachers => { return [ ...teachers, teacher ] })
  }
  return (
    <>
      <div>
        <div style={{ margin: 50 }}>
          <p className="title-no-hover">Teachers</p>
          <div className="divider"></div>
          <div style={{ height: "50px" }}></div>
          {loading ? <div style={{position:"absolute",top: "50%",left:"50%"}}> <Spinner animation="border" style={{ color: "#126664" }} /></div> :
            <div className="grid_teacher">
              {teachers.map((element) => (
                <TeacherTile key={element.id} teacher={element} onEdit={onUpdate} onDelete={onDelete}/>
              ))}
            </div>}
        </div>
      </div>
      <div className="add_teacher" onClick={() => setShow(true)}>
        <FaPlus className="add_icon" />
      </div>
      <AddTeacher show={show} onHide={() => setShow(false)} onAdd={onAdd} />
    </>
  );
}

export default Teacher;
