import { Button } from "react-bootstrap";
import { BsCloudUpload } from "react-icons/bs";
import FilePicker from "./FilePicker/file-picker";
import "./drag-and-drop.css";
const DragDrop = ({onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop, hover, accept, onImageLoad, onClick}) => {
  console.log( onDragOver);
  return (
    <div
      style={{
        boxShadow: `${hover ? "0 0 5px 0 #126664" : "0px 0px 0px #000"
          }`,
        backgroundColor: `${hover ? "#ecf3f3" : "#fff"}`,
      }}
      className={`glow`}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <BsCloudUpload size="7vh" color="#126664" />
      <div
        style={{
          fontSize: "2.4vh",
          fontWeight: "900",
          color: "#5A5A5A",
          verticalAlign: "text-bottom",
        }}
      >
        Drag and drop files here
      </div>
      <div style={{ fontSize: "1.7vh", fontWeight: "500", color: "#666666" }}>
        or
      </div>
      <FilePicker
        accept={accept}
        onChange={onImageLoad}
        onError={(errMsg) => console.log(errMsg)}
      >
        <Button
          style={{
            backgroundColor: "#126664",
            color: "#fff",
            border: "1px solid rgba(0,0,0,0)",
            padding: "10px 20px",
            alignSelf: "center",
            fontSize: "1.7vh",
            fontWeight: "bold",
          }}
          onClick={onClick}
        >
          Browse Files
        </Button>
      </FilePicker>
    </div>
  );
};

export default DragDrop;
