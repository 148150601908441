import React from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { MdPersonOutline, MdDelete } from "react-icons/md";
import { FaRegEdit, } from "react-icons/fa";
import "./TeacherTile.css";
import { deleteFileToStorage, deleteTeacher } from "../services/firebaseServices";
import DeleteConfirm from "./Dialogs/delete-confirm";
import EditTeacher from "./Dialogs/edit-teacher";
const TeacherTile = ({ teacher,onEdit,onDelete }) => {
  const [deleteShow, setDeleteShow] = React.useState(null);
  const [editShow, setEditShow] = React.useState(null);

  const onDeleteClick = () => {
    console.log('delete');
    setDeleteShow(true);
    console.log(deleteShow);
  }

  const onDeleted =async ()  => {
    onDelete(teacher);
    await deleteTeacher(teacher.id)
    await deleteFileToStorage(`teachers/${teacher.id}`);
  }
  return (
    <>
      <ContextMenuTrigger id={teacher.id}>
        <div className='teacher-border'>
          <span className="folder">
            <MdPersonOutline size={26} color="#126664" />&nbsp;&nbsp;&nbsp;
        {teacher.name}
          </span>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={teacher.id}>
        <MenuItem onClick={() => { setEditShow(true); }}><FaRegEdit color="#126664" /><span>Edit</span></MenuItem>
        <MenuItem onClick={onDeleteClick}><MdDelete color="#126664" /><span>Delete</span></MenuItem>
      </ContextMenu>
      <DeleteConfirm show={deleteShow} onHide={() => setDeleteShow(false)} onClick={onDeleted}/>
      <EditTeacher show={editShow} onHide={() => setEditShow(false)} teacher={teacher} onEdit={onEdit}/>
    </>
  );
}

export default TeacherTile;
