import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import DragDrop from '../drag-and-drop'
import AssignTeacher from './assign-teacher';

const AddAudio = ({ display, onHide, teachers, onAdd }) => {
    let history = useHistory();
    let location = useLocation();


    const [audio, setAudio] = useState(null);
    const [hover, setHover] = useState(false);
    const onAudioLoad = (file) => {
        setAudio(() => file)
        console.log(audio)
        onSave()
    };
    const [show, setShow] = useState(false);

    const onSave = () => {
        setShow(val => true);
    }

    const dragOver = (e) => {
        e.preventDefault();
        console.log("over");
        setHover(true);
    };

    const dragEnter = (e) => {
        console.log("enter");
        e.preventDefault();
    };

    const dragLeave = (e) => {
        console.log("exit");
        e.preventDefault();
        setHover(false);
    };


    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            setAudio(files[0]);
            onSave()
            console.log(files[0]);
            console.log(files[0].type);
        }
    };

    const onExit = () => {
        setAudio(null);
        setHover(false);
        onHide()
    };

    return (
        <>
            <Modal show={display} onHide={onExit} onExit={onExit} onEscapeKeyDown={onExit} centered>
                <div style={{ margin: "40px 40px",display: 'flex', justifyContent: 'center' }}>
                    <DragDrop
                        accept="audio/*"
                        onDragOver={dragOver}
                        onImageLoad={onAudioLoad}
                        onDragEnter={dragEnter}
                        hover={hover}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                    />
                </div>
            </Modal>
            {show && <AssignTeacher show={show} onHide={() => {
                setShow(e => false)
                onExit()
            }} teachers={teachers} file={audio} path={location.pathname} onAdd={onAdd} />}
        </>
    )
}

export default AddAudio
