/* eslint-disable default-case */
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAeTZYT0kgMCVrcHPqB6nbnM_QcHxlv5fg", // Auth / General Use
  appId: "1:907295147789:web:8f7238a08c19f43bcb5244", // General Use
  projectId: "rambam-f0d2d", // General Use
  authDomain: "rambam-f0d2d.firebaseapp.com", // Auth with popup/redirect
  storageBucket: "rambam-f0d2d.appspot.com", // Storage
  messagingSenderId: "907295147789",
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);
console.log("firebase init");
const storage = firebase.storage();

const auth = firebase.auth();

const database = firebase.firestore();

export { firebase, database, auth, storage as default };

export async function uploadFileToStorage(path, file, onProgress, onComplete) {
  console.log(file);
  var blob = await new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: file.type,
  });
  console.log(blob);
  var uploadTask = storage.ref(`${path}`).put(blob);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress(
        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      );
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        onComplete(downloadURL);
        console.log("File available at", downloadURL);
      });
    }
  );
}

export async function deleteFileToStorage(path) {
  console.log(path);
  await storage.ref().child(path).delete();
}

export async function signIn(email, password, onError, onComplete) {
  return await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => onComplete(user))
    .catch((err) => onError(err));
}

export async function uploadAudio(type, media) {
  if (media.duration === 0) {
    console.log(type, media);
    alert("Duration is Zero");
    return null;
  }
  console.log(type, media);
  var res = await database.collection(type).add(media);
  return res.id;
}

export async function uploadTeacher(teacher, id) {
  const ref = database.collection("teachers").doc(id);
  await ref
    .set(teacher, { merge: true })
    .then((val) => console.log("Upload Successfull", val))
    .catch((err) => console.log(err));
}

export async function updateTeacher(teacher, id) {
  const ref = database.collection("teachers").doc(id);
  await ref
    .update(teacher)
    .then((val) => console.log("Update Successfull", val))
    .catch((err) => console.log(err));
}

export async function updateAudio(audio, id) {
  const ref = database.collection("Audio").doc(id);
  await ref
    .update(audio)
    .then((val) => console.log("Update Successfull", val))
    .catch((err) => console.log(err));
}
export async function updateAudioTextSync(
  textSyncMap,
  nonIndexedTextSyncMap,
  id
) {
  const ref = database.collection("Audio").doc(id);
  await ref
    .update({
      textSyncMap: textSyncMap,
      nonIndexedTextSyncMap: nonIndexedTextSyncMap,
    })
    .then((val) => console.log("Update Successfull", val))
    .catch((err) => console.log(err));
}
export async function deleteTeacher(id) {
  const ref = database.collection("teachers").doc(id);
  await ref
    .delete()
    .then((val) => console.log("Delete Successfull", val))
    .catch((err) => console.log(err));
}
export async function deleteAudio(id) {
  const ref = database.collection("Audio").doc(id);
  await ref
    .delete()
    .then((val) => console.log("Delete Successfull", val))
    .catch((err) => console.log(err));
}

export async function getAudio(path) {
  console.log("geting the audio");
  return await database
    .collection("Audio")
    .where("path", "==", path)
    .get()
    .then((doc) =>
      doc.docs.map((e) => {
        return { ...e.data(), id: e.id };
      })
    );
}

export async function utils() {
  console.log("geting the audio");
  await database
    .collection("Audio")
    .doc("0dPmVJS2xWP3E7AwboEc")
    .get()
    .then((doc) => {
      console.log(doc.data(), doc.id);
      var audio = document.createElement("audio");
      audio.src = doc.data().url;
      audio.addEventListener("loadedmetadata", async function () {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        var duration = audio.duration * 1000;
        await database
          .collection("Audio")
          .doc(doc.id)
          .update({
            duration: duration,
          })
          .then((val) => console.log("Update Successfull", val))
          .catch((err) => console.log(err));
        // example 12.3234 seconds
        console.log("The duration of the song is of: " + duration + " seconds");

        // Alternatively, just display the integer value with
        // parseInt(duration)
        // 12 seconds
      });
    });
}

export async function getTeachers() {
  return await database
    .collection("teachers")
    .get()
    .then((doc) =>
      doc.docs.map((e) => {
        return { ...e.data(), id: e.id };
      })
    );
}
