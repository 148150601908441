import HebData from '../data/hebnum.json'

export function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return (hours < 1 ? "" : (hours + ":")) + (minutes < 1 ? "00:" : (minutes + ":")) + seconds;
}

export function engNumToHeb(num){
   return HebData.find((e)=> e.number === num).heb
} 