import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import SideBar from "../components/Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import Teacher from "../pages/Teachers";
import Classes from "../pages/Classes";

function Home() {
  let location = useLocation();
  let routes = [
    {
      path: "/",
      exact: true,
      widget: () => <Redirect to="/classes"> </Redirect>,
    },
    {
      path: "/classes/:bookId?/:sectionId?/:chapterId?/:sync?",
      widget: () => <Classes />,
    },
    {
      path: "/teachers",
      widget: () => <Teacher />,
    },
  ];
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <SideBar />
          </Col>
          <Col xs={10}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.widget key={location.pathname} />}
                />
              ))}
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
