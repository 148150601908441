import { React, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import Logo from "./Logo";
import { FaHeadphonesAlt, FaRegUser } from "react-icons/fa";

function SideBar() {
  const location = useLocation();
  const [hoverValue, changeHoverState] = useState(0);
  return (
    <>
      <Nav
        className="col-md-2 bg-white sidebar flex-column d-none d-md-block "
        activeKey="/home"
      >
        <Logo />
        <Link to="/classes" style={{ textDecoration: "none" }}>
          <Nav.Item style={{ margin: "0.5rem", marginTop: "7vh" }}>
            <Container
              fluid
              onMouseEnter={() => changeHoverState(1)}
              onMouseLeave={() => changeHoverState(0)}
              style={{
                padding: "2vh",
                backgroundColor: `${
                  location.pathname.includes("/classes")
                    ? "#ECF3F3"
                    : hoverValue === 1
                    ? "#efefef"
                    : "#fff"
                }`,
                borderRadius: "5px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
            >
              <span
                className="sidebar-item"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <FaHeadphonesAlt
                  className="sidebar-item"
                  size={20}
                  style={{ marginRight: "2vh" }}
                />
                Classes
              </span>
            </Container>
          </Nav.Item>
        </Link>
        <Link to="/teachers" style={{ textDecoration: "none" }}>
          <Nav.Item style={{ margin: "0.5rem" }}>
            <Container
              fluid
              onMouseEnter={() => changeHoverState(2)}
              onMouseLeave={() => changeHoverState(0)}
              style={{
                padding: "2vh",
                backgroundColor: `${
                  location.pathname.includes("/teachers")
                    ? "#ECF3F3"
                    : hoverValue === 2
                    ? "#efefef"
                    : "#fff"
                }`,
                borderRadius: "5px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
            >
              <span
                className="sidebar-item"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <FaRegUser
                  className="sidebar-item"
                  size={20}
                  style={{ marginRight: "2vh" }}
                />
                Teachers
              </span>
            </Container>
          </Nav.Item>
        </Link>
        <Navbar.Text id="bottom" style={{ color: "#126664" }}>
          Powered By <span style={{ fontWeight: "bold" }}>Osfy</span>
        </Navbar.Text>
      </Nav>
    </>
  );
}

export default SideBar;
