import React, { useState } from 'react';
import { v4 } from 'uuid';
import { uploadFileToStorage, uploadTeacher } from '../../services/firebaseServices'
import { Modal, Row, Col, Button } from 'react-bootstrap';
import DragDrop from '../drag-and-drop'
import ImageEditor from '../ImageEditor';
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
const AddTeacher = (props) => {
    const [scale, setScale] = useState(1.25);
    const [image, setImage] = useState(null);
    const [imageBlob, setImageBlob] = useState(null);
    const [hover, setHover] = useState(false);
    const [name, setName] = useState('');

    const onImageLoad = (file) => {
        setImage(file);
    };

    const onSave = () => {
        var id = v4();
        uploadFileToStorage(
            `teachers/${id}`, imageBlob, (val) => console.log(val), (val) => {
                uploadTeacher({
                    name: name,
                    imageUrl: val
                }, id);
                props.onAdd(
                    {
                        id: id,
                        name: name,
                        imageUrl: val
                    }
                )
                props.onHide();
            }
        );
    }

    const dragOver = (e) => {
        e.preventDefault();
        console.log("over");
        setHover(true);
    };


    const dragEnter = (e) => {
        console.log("enter");
        e.preventDefault();
    };

    const dragLeave = (e) => {
        console.log("exit");
        e.preventDefault();
        setHover(false);
    };

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            setImage(files[0]);
            console.log(files[0]);
        }
    };

    const onNameChanged = (e) => {
        e.preventDefault();
        setName(name => e.target.value)
    }

    const onExit = () => {
        setImage(null);
        setHover(false);
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={onExit}
            size="md"
            contentClassName="custom-modal-style"
            onEscapeKeyDown={onExit}
            onExit={onExit}
            centered
        >
            <Modal.Body style={{ padding: "4vh" }}>
                <div className="d-flex justify-content-center">
                    <input
                        defaultValue="Rabbi Shmuel Yehudah Avtzon"
                        type="text"
                        onChange={onNameChanged}
                        name="name"
                        style={{
                            width: "18vw",
                            fontStyle: "2.5vh",
                            height: "5vh",
                            padding: "5px 20px",
                            border: "1px solid #D8D8D8",
                            borderRadius: "3px",
                        }}
                    />
                </div>
                <div style={{ height: "40px" }}></div>
                {image != null ? (
                    <Row className="align-items-end">
                        <Col xs={2}></Col>
                        <Col xs={8} className="text-center">
                            <ImageEditor
                                onImagePreview={(e) => setImageBlob((blob) => e)}
                                image={image}
                                onLoadFailure={() => console.log("load failure")}
                                onLoadSuccess={() => console.log("load success")}
                                onImageReady={() => console.log("image ready")}
                                onImageChange={() => console.log("image change")}
                                style={{ borderRadius: "6px" }}
                                scale={scale}
                                color={[255, 255, 255, 0.5]}
                            />
                        </Col>
                        <Col xs={2} className="text-left">
                            <Row>
                                <FiZoomIn
                                    size={24}
                                    onClick={() => setScale((scale) => scale + 0.25)}
                                    color="#126462"
                                />
                            </Row>
                            <Row>
                                <div style={{ paddingBottom: "5px" }}></div>
                            </Row>
                            <Row>
                                <FiZoomOut
                                    size={24}
                                    onClick={() =>
                                        setScale((scale) => {
                                            if (scale > 1) {
                                                console.log(scale);
                                                return scale - 0.25;
                                            } else {
                                                return 1;
                                            }
                                        })
                                    }
                                    color="#126462"
                                />
                            </Row>
                            <Row>
                                <div style={{ paddingBottom: "10px" }}></div>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <DragDrop
                            onDragOver={dragOver}
                            onImageLoad={onImageLoad}
                            accept="image/*"
                            onDragEnter={dragEnter}
                            hover={hover}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                        />
                    </div>
                )}
                <div style={{ height: "40px" }}></div>

                <div className="d-flex justify-content-center">
                    <Button
                        style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            color: "#126664",
                            border: "1px solid #126664",
                            height: "5vh",
                            width: "8vw",
                            margin: "0px 0.8vw",
                        }}
                        onClick={onExit}
                    >
                        Cancel
            </Button>
                    <Button
                        style={{
                            backgroundColor: "#126664",
                            color: "#fff",
                            border: "1px solid rgba(0,0,0,0)",
                            height: "5vh",
                            width: "8vw",
                            margin: "0px 0.8vw",
                        }}
                        onClick={onSave}
                    >
                        Save
            </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddTeacher;