import { Modal, Button } from "react-bootstrap"
import { FiAlertCircle } from "react-icons/fi";

const DeleteConfirm = ({ show, onHide, onClick }) => {

    const onDelete = () => {
        onClick();
        onHide();
    }
    return <Modal size="md" show={show} onHide={onHide} onEscapeKeyDown={onHide}
        onExit={onHide}
        centered>
        <Modal.Body style={{padding: "40px"}}>
            <div className="text-center"><FiAlertCircle color="#126664" size={80} /></div>
            <div className="text-center"><p style={{ fontSize: 30, textAlign: "center",margin:"20px" }}>Are you sure want to delete it?</p></div>

            <div className="d-flex justify-content-center">
                <Button
                    style={{
                        backgroundColor: "rgba(0,0,0,0)",
                        color: "#126664",
                        border: "1px solid #126664",
                        height: "5vh",
                        width: "8vw",
                        margin: "0px 0.8vw",
                    }}
                    onClick={onHide}
                >
                    Cancel
            </Button>
                <Button
                    style={{
                        backgroundColor: "#126664",
                        color: "#fff",
                        border: "1px solid rgba(0,0,0,0)",
                        height: "5vh",
                        width: "8vw",
                        margin: "0px 0.8vw",
                    }}
                    onClick={onDelete}
                >
                    Ok
            </Button>
            </div>
        </Modal.Body>
    </Modal>
}

export default DeleteConfirm;