import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import React, { useEffect, useState } from 'react'
import Login from "./pages/Login";
import Home from "./pages/Home";
import { auth } from "./services/firebaseServices";

function App() {
  const [, setUser] = useState(null);
  let location = useLocation();
  let history = useHistory();
  const routes = [
    {
      path: "/login",
      widget: () => <Login />
    },
    {
      path: "/",
      widget: () => <Home />
    }
  ]

  useEffect(() => {
    var unsubscribe = auth.onAuthStateChanged(user => {
      setUser(() => user)
      if (user !== null ) {
        if (location.pathname.match("login")) {
          history.replace("")
        }
      }
      else {
        history.replace("/login")
      }
    });
    return () => unsubscribe(); 
  }, [])
  return (
        <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.widget key={location.pathname} />}
          />
        ))}
      </Switch>
  );
}

export default App;
