import { React, useState, useEffect } from "react";
import Folder from "../components/Folder";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import DragDrop from "../components/drag-and-drop";
import AssignTeacher from "../components/Dialogs/assign-teacher";
import structure from "../data/structure.json";
import "./Classes.css";
import { getAudio, getTeachers } from "../services/firebaseServices";
import { v4 } from "uuid";
import AudioTextSync from "./AudioTextSync";
import SampleText from "../data/SampleText";
import Media from "../components/Media";
import AddAudio from "../components/Dialogs/add-audio";
import { Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
const Classes = (props) => {
  let history = useHistory();
  let location = useLocation();

  const [audio, setAudio] = useState(null);
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(0);
  const [audios, setAudios] = useState([]);

  const onAudioLoad = (file) => {
    setAudio(() => file);
    console.log(audio);
    onSave();
  };

  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [assign, setAssign] = useState(false);

  const state = location?.state?.data;
  const getData = async () => {
    setLoading(true);
    const teacher = await getTeachers();
    let audios = await getAudio(location.pathname.split("classes/")[1]);
    setAudios((audio) => audios);
    setTeachers(() => teacher);
    setLoading((val) => false);
    console.log(teacher);
    console.log("Getting");
    console.log(audios);
    console.log("  ");
  };
  useEffect(() => {
    if (location.pathname.split("/").length === 5) {
      console.log("1");
      getData();
      console.log(audios);
    }
  }, []);

  const onEdit = (media) => {
    console.log(media.teacher);
    setAudios((audios) =>
      audios.map((ele) => {
        if (ele.id === media.id) {
          return media;
        }
        return ele;
      })
    );
  };

  const onDelete = (media) => {
    setAudios((audios) => audios.filter((ele) => ele.id !== media.id));
  };

  const onSave = () => {
    setAssign((val) => true);
  };

  const dragOver = (e) => {
    e.preventDefault();
    console.log("over");
    setHover(true);
  };

  const dragEnter = (e) => {
    console.log("enter");
    e.preventDefault();
  };

  const dragLeave = (e) => {
    console.log("exit");
    e.preventDefault();
    setHover(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setAudio(files[0]);
      onSave();
      console.log(files[0]);
    }
  };

  const onExit = () => {
    setAudio(null);
    setHover(false);
  };

  const [selected, changeSelected] = useState();
  var heading = [
    <p
      className={location.pathname === "/classes" ? "title-no-hover" : "title"}
      onClick={() =>
        location.pathname === "/classes" ? null : history.push("/classes")
      }
    >
      Mishnah Torah
    </p>,
  ];
  var ids = location.pathname.substring(9).split("/");
  ids = ids.map((e) => e.replace("%20", " "));
  if (location.pathname.split("/").length >= 3)
    heading.push(
      ...[
        <IoIosArrowForward className="icon" size={20} />,
        <p
          className={ids.length === 1 ? "title-no-hover" : "title"}
          onClick={() =>
            ids.length === 1 ? null : history.push(`/classes/${ids[0]}`)
          }
        >
          {ids[0]}
        </p>,
      ]
    );
  if (location.pathname.split("/").length >= 4)
    heading.push(
      ...[
        <IoIosArrowForward className="icon" size={20} />,
        <p
          className={ids.length === 2 ? "title-no-hover" : "title"}
          onClick={() =>
            ids.length === 2
              ? null
              : history.push(`/classes/${ids[0]}/${ids[1]}`)
          }
        >
          {ids[1]}
        </p>,
      ]
    );
  if (location.pathname.split("/").length >= 5)
    heading.push(
      ...[
        <IoIosArrowForward className="icon" size={20} />,
        <p
          className={ids.length === 3 ? "title-no-hover" : "title"}
          onClick={() =>
            ids.length === 2
              ? null
              : history.push(`/classes/${ids[0]}/${ids[1]}/${ids[2]}`)
          }
        >{`Chapter ${ids[2]}`}</p>,
      ]
    );
  if (location.pathname.split("/").length >= 6)
    heading.push(
      ...[
        <IoIosArrowForward className="icon" size={20} />,
        <p className="title-no-hover">{`Text Syncing`}</p>,
      ]
    );

  return (
    <>
      <div>
        {location.pathname.split("/").length === 6 ? (
          <AudioTextSync header={heading} media={state} />
        ) : (
          <div style={{ margin: "50px" }}>
            <span>{heading}</span>
            <div className="divider"></div>
            {location.pathname.split("/").length === 5 ? (
              loading ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  {" "}
                  <Spinner animation="border" style={{ color: "#126664" }} />
                </div>
              ) : audios.length < 1 ? (
                <div className="drag-drop">
                  <DragDrop
                    accept="audio/*"
                    onDragOver={dragOver}
                    onImageLoad={onAudioLoad}
                    onDragEnter={dragEnter}
                    hover={hover}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                  />
                </div>
              ) : (
                audios.map((ele) => (
                  <Media
                    onDelete={onDelete}
                    teachers={teachers}
                    forMedia={true}
                    forGrid={false}
                    onDoubleClick={() =>
                      history.push({
                        pathname: location.pathname + `/sync`,
                        state: {
                          data: {
                            ...ele,
                            teacher: {
                              ...teachers.find((teach) => {
                                return teach.id === ele.teacher;
                              }),
                            },
                          },
                        },
                      })
                    }
                    key={ele.id}
                    onEdit={onEdit}
                    media={{
                      ...ele,
                      teacher: {
                        ...teachers.find((teach) => {
                          return teach.id === ele.teacher;
                        }),
                      },
                    }}
                  />
                ))
              )
            ) : location.pathname.split("/").length === 4 ? (
              <div style={{ marginTop: "40px" }}>
                {structure
                  .find((e) => e.name === ids[0])
                  .topics.find((e) => e.name === ids[1])
                  .chapters.map((obj, i) => (
                    <Folder
                      forGrid={false}
                      key={i + 1}
                      title={"Chapter " + (i + 1)}
                      selected={selected === (i + 1).toString()}
                      onClick={() => changeSelected((i + 1).toString())}
                      onDoubleClick={() =>
                        history.push(location.pathname + `/${i + 1}`)
                      }
                    />
                  ))}
              </div>
            ) : location.pathname.split("/").length === 4 ? (
              <div style={{ marginTop: "40px" }}>
                {structure
                  .find((e) => e.name === ids[0])
                  .topics.find((e) => e.name === ids[1])
                  .chapters.map((obj, i) => (
                    <Folder
                      forGrid={false}
                      key={i + 1}
                      title={"Chapter " + (i + 1)}
                      selected={selected === (i + 1).toString()}
                      onClick={() => changeSelected((i + 1).toString())}
                      onDoubleClick={() =>
                        history.push(location.pathname + `/${i + 1}`)
                      }
                    />
                  ))}
              </div>
            ) : (
              <div className="grid">
                {location.pathname.split("/").length === 3
                  ? structure
                      .find((e) => e.name === ids[0])
                      .topics.map((obj) => (
                        <Folder
                          forGrid={true}
                          key={v4()}
                          title={obj.name}
                          selected={selected === obj.name}
                          onClick={() => changeSelected(obj.name)}
                          onDoubleClick={() =>
                            history.push(location.pathname + `/${obj.name}`)
                          }
                        />
                      ))
                  : structure.map((obj) => (
                      <Folder
                        forGrid={true}
                        key={obj.name}
                        title={obj.name}
                        selected={selected === obj.name}
                        onClick={() => changeSelected(obj.name)}
                        onDoubleClick={() =>
                          history.push("/classes/" + `${obj.name}`)
                        }
                      />
                    ))}
              </div>
            )}
          </div>
        )}
      </div>
      {audios.length > 0 && (
        <div className="add_teacher" onClick={() => setShow(true)}>
          <FaPlus className="add_icon" />
        </div>
      )}
      {assign && (
        <AssignTeacher
          show={assign}
          onHide={() => {
            setAssign(false);
            onExit();
          }}
          teachers={teachers}
          file={audio}
          path={location.pathname}
          onAdd={(audio) => setAudios((audios) => [...audios, audio])}
        />
      )}
      <AddAudio
        display={show}
        teachers={teachers}
        onHide={() => setShow(false)}
        onAdd={(audio) => setAudios((audios) => [...audios, audio])}
      />
    </>
  );
};

export default Classes;
